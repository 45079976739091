export default {
  // main navigation - side menu
  menu: [
    {
      text: 'headlineDashboard',
      key: '',
      requiredAccess: '',
      requiredRole: 'CONSUMER',
      items: [
        { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'dashboard', link: '/main/dashboard/', requiredAccess: '', requiredRole: 'CONSUMER' },
        {
          icon: 'mdi-database-settings-outline', key: 'menu.upload', text: 'dataManagement', link: '/main/data-management/', requiredAccess: '', requiredRole: 'CONSUMER', regex: /\/data-management\//,
          items: [
            { icon: 'mdi-table-of-contents', key: 'menu.FileOverview', text: 'overview', link: '/main/data-management/overview/', requiredAccess: '', requiredRole: 'CONSUMER' },
            { icon: 'mdi-database-plus-outline', key: 'menu.FileUpload', text: 'upload', link: '/main/data-management/upload/', requiredAccess: '', requiredRole: 'CONSUMER' },
            { icon: 'mdi-table-multiple', key: 'menu.LutOverview', text: 'lutOverview', link: '/main/data-management/lut/', requiredAccess: '', requiredRole: 'CONSUMER' },
          ],
        },
      ],
    },
    {
      text: 'headlineDataQuality',
      requiredAccess: '',
      requiredRole: '',
      items: [
        {
          icon: 'mdi-flask-outline', key: 'menu.categorylab', text: 'categorylab', requiredAccess: 'CATEGORIZER', requiredRole: 'CONSUMER', regex: /\/categorylab\//,
          items: [
            { icon: 'mdi-table-of-contents', key: 'menu.categorylabDashboard', text: 'overview', link: '/main/categorylab/dashboard', requiredAccess: 'CATEGORIZER', requiredRole: 'CONSUMER' },
            { icon: 'mdi-clipboard-edit-outline', key: 'menu.categorylabRules', text: 'rules', link: '/main/categorylab/rules/', requiredAccess: 'CATEGORIZER', requiredRole: 'CONSUMER' },
            { icon: 'mdi-robot-outline', key: 'menu.categorylabModel', text: 'model', link: '/main/categorylab/model/', requiredAccess: 'LLM', requiredRole: 'CONSUMER' },
            { icon: 'mdi-chart-bubble', key: 'menu.cluster', text: 'cluster', link: '/main/cluster/', requiredAccess: 'CLUSTER', requiredRole: 'CONSUMER' },
          ],
        },
      ],
    },
    {
      text: 'headlineAnalytics',
      requiredAccess: 'RESEARCH',
      requiredRole: '',
      items: [
        {
          icon: 'mdi-chart-arc', key: 'menu.research', text: 'spendAnalytics', link: '/main/research/', requiredAccess: 'RESEARCH', requiredRole: 'CONSUMER', regex: /\/research\//,
          items: [
            { icon: 'mdi-monitor-dashboard', key: 'menu.researchDashboard', text: 'spendAnalyticsDashboard', link: '/main/research/dashboard/', requiredAccess: 'RESEARCH', requiredRole: 'CONSUMER' },
            { icon: 'mdi-sitemap', key: 'menu.researchCategoryDetail', text: 'categoryDetails', link: '/main/research/category/', requiredAccess: 'RESEARCH', requiredRole: 'CONSUMER' },
            { icon: 'mdi-domain', key: 'menu.researchVendors', text: 'vendorDetails', link: '/main/research/vendor/', requiredAccess: 'RESEARCH', requiredRole: 'CONSUMER' },
            { icon: 'mdi-chart-areaspline', key: 'menu.researchUnitPrices', text: 'unitPrices', link: '/main/research/unit/', requiredAccess: 'RESEARCH', requiredRole: 'CONSUMER' },
            { icon: 'mdi-table-headers-eye', key: 'menu.researchPivot', text: 'pivot', link: '/main/research/pivot/', requiredAccess: 'PIVOT', requiredRole: 'CONSUMER' },
            { icon: 'mdi-table-of-contents', key: 'menu.researchTransactions', text: 'transactions', link: '/main/research/transactions/', requiredAccess: 'RESEARCH', requiredRole: 'CONSUMER' },
          ],
        },
        { icon: 'mdi-gauge', key: 'menu.kpiDashboard', text: 'kpiDashboard', link: '/main/kpi/', requiredAccess: 'KPI', requiredRole: 'CONSUMER', regex: /\/kpi\// },
        { icon: 'mdi-brain', key: 'menu.insights', text: 'spendInsights', link: '/main/spend-insights/', requiredAccess: 'INSIGHTS', requiredRole: 'CONSUMER' },
        {
          icon: 'mdi-strategy', key: 'menu.actions', text: 'savingsActions', link: '/main/actions/', requiredAccess: 'ACTION_LOG', requiredRole: 'CONSUMER', regex: /\/actions\//,
          items: [
            { icon: 'mdi-strategy', key: 'menu.researchDashboard', text: 'actionLog', link: '/main/actions/projects/', requiredAccess: 'ACTION_LOG', requiredRole: 'CONSUMER' },
            { icon: 'mdi-cash-check', key: 'menu.savingsDashboard', text: 'savingsDashboard', link: '/main/actions/savings/', requiredAccess: 'ACTION_LOG', requiredRole: 'CONSUMER' },
          ],
        },
        { icon: 'mdi-cloud-download-outline', key: 'menu.reportsDashboard', text: 'reportsDashboard', link: '/main/reports/', requiredAccess: 'REPORTS', requiredRole: 'CONSUMER', regex: /\/reports\// },
      ],
    },
    {
      text: 'headlineMeta',
      requiredAccess: '',
      requiredRole: '',
      items: [
        // { icon: 'mdi-information-variant', key: 'menu.documentation', text: 'documentation', link: '/main/documentation/', requiredAccess: '', requiredRole: 'CONSUMER' },
        { icon: 'mdi-account', key: 'menu.profiles', text: 'profile', link: '/main/profile/view/', requiredAccess: '', requiredRole: 'CONSUMER' },
        {
          icon: 'mdi-cog-outline', key: 'menu.admin', text: 'settings', link: '/main/admin/', requiredAccess: '', requiredRole: 'ADMIN', regex: /\/admin\//,
          items: [
            { icon: 'mdi-account-group', key: 'menu.users', text: 'userManagement', link: '/main/admin/', requiredAccess: '', requiredRole: 'ADMIN' },
            { icon: 'mdi-factory', key: 'menu.facts', text: 'facts', link: '/main/admin/facts/', requiredAccess: '', requiredRole: 'ADMIN' },
            { icon: 'mdi-flask-outline', key: 'menu.categorizerSchema', text: 'categorylabSchema', link: '/main/admin/categorizer/schema', requiredAccess: 'CATEGORIZER', requiredRole: 'ADMIN' },
            { icon: 'mdi-bank', key: 'menu.settings', text: 'settingsOfOrganization', link: '/main/admin/organization/settings', requiredAccess: '', requiredRole: 'ADMIN' },
            { icon: 'mdi-connection', key: 'menu.etl', text: 'etl', link: '/main/admin/organization/etl', requiredAccess: '', requiredRole: 'ADMIN' },
          ],
        },
      ],
    },
    // Next are only for SUPERUSER!
    {
      text: 'headlineSuperSettings',
      requiredAccess: '',
      requiredRole: 'SUPERUSER',
      items: [
        { icon: 'mdi-lock-check-outline', key: 'menu.organizationAll', text: 'globalOrganizations', link: '/main/admin/organization/all', requiredAccess: '', requiredRole: 'SUPERUSER' },
        { icon: 'mdi-lock-check-outline', key: 'menu.salesCAse', text: 'salesCaseSettings', link: '/main/admin/case', requiredAccess: '', requiredRole: 'SUPERUSER' }, // TODO CHECK
      ],
    },
  ],

  // footer links
  footer: [
    {
      text: 'Über kiresult',
      key: 'menu.kiresult',
      href: 'https://www.kiresult.com',
      target: '_blank',
    },
  ],
};
